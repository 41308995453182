.select2-container {
    outline: none;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table;
    table-layout: fixed;
}

.select2-results__option--highlighted {
  background-color: $red !important;
}

.select2-container--open{
    z-index: 1100;
}
