.note-popover .popover-content .note-dropdown-menu.note-check a:not(.checked) i,
.note-toolbar .note-dropdown-menu.note-check a:not(.checked) i {
    color: #000 !important;
    visibility: visible !important;
}

.note-icon-menu-check::before {
    content: none !important;
}

.dropdown-item.checked {
    background-color: $red !important;
}

.dropdown-item.checked .note-icon-menu-check,
.dropdown-item:hover .note-icon-menu-check,
.dropdown-item:focus .note-icon-menu-check {
    color: #fff !important;
}

.note-editor.note-frame .note-editing-area .note-editable[contenteditable=false],
.note-editor.note-airframe .note-editing-area .note-editable[contenteditable=false] {
    background-color: transparent !important;
}
