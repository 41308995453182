table.dataTable {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
    font-size: 0.875rem;
}
/* top-left border-radius */
table.dataTable tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

/* top-right border-radius */
table.dataTable tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

/* bottom-left border-radius */
table.dataTable tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

/* bottom-right border-radius */
table.dataTable tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

table.dataTable thead {
    background-color: $red;
    font-family: "DINPro-Bold";
    color: #fff;
    text-transform: uppercase;
}

table.dataTable th {
    border-bottom: 0px;
}

table.dataTable tr:first-child th:not(:first-child) {
    border-left: 2px solid #fff;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0.1em;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item {
    padding: 0 5px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item.active .page-link {
    font-family: "DINPro-Bold";
    border-radius: 2px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item:not(.active):not(.disabled) .page-link {
    color: #000;
    background-color: $lightGray;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item .page-link {
    border: 0;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item:not(.active) .page-link:hover {
    color: #fff;
    background-color: $gray;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
    background-color: $green;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th.dtr-control:before {
    background-color: $red;
}

table.dataTable tbody td {
    vertical-align: middle;
}

table.dataTable a.dt-customer-users {
    color: #000;
}

table.dataTable a.dt-customer-users:hover {
    font-family: "DINPro-Bold";
    text-decoration: none;
}

table.dataTable#table-campaigns thead .sorting:before,
table.dataTable#table-campaigns thead .sorting_asc:before,
table.dataTable#table-campaigns thead .sorting_desc:before,
table.dataTable#table-campaigns thead .sorting_asc_disabled:before,
table.dataTable#table-campaigns thead .sorting_desc_disabled:before {
    padding-bottom: 13px;
}

table.dataTable#table-campaigns thead .sorting:after,
table.dataTable#table-campaigns thead .sorting_asc:after,
table.dataTable#table-campaigns thead .sorting_desc:after,
table.dataTable#table-campaigns thead .sorting_asc_disabled:after,
table.dataTable#table-campaigns thead .sorting_desc_disabled:after {
    padding-bottom: 13px;
}

div.dataTables_wrapper div.dataTables_processing{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: fixed;
    z-index: 1000;
    opacity: 1;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 18px;
    display: grid;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    padding-top: 20%;
}