.form-check input:hover,
.form-check label:hover {
    cursor: pointer !important;
}

form:not(#advanced-search-form) label {
    font-size: 0.875rem;
}

.pretty input[type="checkbox"] {
    max-width: 20px
}

/* Overwriting some Pretty-Checkbox styles to allow for word wrapping */
.pretty {
    white-space: normal;
    width: 100%;
}

.pretty .state label 
{
    text-indent: 0;
    padding-left: 2rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon 
{
    top: 0;
}

.page-coupon-details input:disabled,
.page-coupon-details select:disabled
{
    background-color: #fff;
}

.custom-file-label::after
{
    content: "Wybierz plik";
    background-color: $red;
    color: #fff;
}

.custom-file-input
{
    cursor: pointer;
}
