
.administrator {

}

/**
    Pulpit po zalogowaniu się
*/
.administrator .page-dashboard .page-dashboard-container {
    width: 100%;
}

.administrator .page-dashboard .dashboard-user-info {
    position: fixed;
    /*width: 388px;*/
    width: 20.5%;
    height: 100%;
    box-shadow: 0px 0px 20px #efefef;
    left: 0;
    top: 0;

    /*background-image: url('/images/bg-dog.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 75%;*/
}

.administrator .page-dashboard .dashboard-user-info .dog-bg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15%;
    margin: 0 auto;
}

.administrator .page-dashboard .dashboard-user-info .rc-logo {
    width: 290px;
    margin: 88px auto 0 auto;
    padding: 0 0 40px 0;
    text-align: center;
    border-bottom: 1px solid $lightGray3;
}

.administrator .page-dashboard .dashboard-user-info .user-info-container {
    width: 290px;
    margin: 50px auto 0 auto;
    text-align: center;
}

.administrator .page-dashboard .user-info-container .avatar {
    margin: 0 0 10px 0;
}

.administrator .page-dashboard .user-info-container .avatar img {
    display: block;
    margin: 0 auto;
}

.administrator .page-dashboard .user-info-container .user-name {
    color: $gray;
    font-size: 1.875rem; /**30px**/
}

.administrator .page-dashboard .user-info-container .user-name > div {
    line-height: 1.875rem; /**30px**/
}

.administrator .page-dashboard .user-info-container .user-name a {
    font-size: 1.375rem; /**22px**/
    display: block;
    margin: 10px 0 0 0;
    text-decoration: none;
}

.administrator .page-dashboard .user-info-container .user-name a:hover {
    font-family: "DINPro-Bold";
    color: $red;
}

.administrator .page-dashboard .user-info-logout-container {
    margin: 37px 0 0 0;
}

.administrator .page-dashboard .user-info-logout-container a {
    display: block;
    color: $gray;
    font-size: 1.125rem; /**18px**/
}


/**
    Pulpit po zalogowaniu się - prawa część
*/
.administrator .page-dashboard .page-dashboard-container .main {
    margin-left: 20.5%;
}

.administrator .page-dashboard .page-dashboard-container .main .container-fluid {
    max-width: 90%;
    margin: 0 auto;
}

.administrator .header-container {
    margin: 48px 0 0 0;
}

.administrator .header-container a {
    color: $gray;
    font-size: 1.125rem; /**18px**/
    line-height: 1.125rem;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
}

.administrator .header-container a.active {
    font-family: "DINPro-Bold";
    color: $red;
}

.administrator .header-container a:hover {
    font-family: "DINPro-Bold";
}

.administrator .header-container a.home-btn span:first-child img:last-child {
    display: none;
}

.administrator .header-container a.home-btn:hover span:first-child img:first-child {
    display: none;
}

.administrator .header-container a.home-btn:hover span:first-child img:last-child {
    display: inline-block;
}

.administrator .header-container a.home-btn img {
    padding-right: 5px;
    display: inline-block;
}

.administrator .header-container .mobile-menu-btn {
    font-size: 25px;
    display: none;
    margin-top: 3px;

    z-index: 1001;
    position: absolute;
}


.administrator .header-container .col-r ul {
    float: right;
    padding: 0;
    margin: 0;
}

.administrator .header-container .col-r ul li {
    list-style: none;
    display: inline-block;
}

.administrator .header-container .col-r ul li:not(:first-child):before {
    content: "|";
    color: $lightGray3;
}

.administrator .header-container .col-r ul li a {
    padding: 0 20px 0 20px;
}

.administrator .header-container .col-r .mobile-header-logo {
    width: 124px;
    display: none;
}

.administrator .page-title-container {
    margin: 78px 0 0 0;
    border-bottom: 1px solid $lightGray3;
}

.administrator .page-title-container h2 {
    color: $red;
    font-size: 1.5625rem; /** 25px **/
    padding: 0 0 7px 0;
}

.administrator .page-title-container h3 {
    color: $gray;
    font-size: 1.2rem;
    padding: 0 0 7px 0;
}

.administrator .box{
    border-radius: 10px;
    border: 1px solid #ccc;
}

.administrator .page-title-container h2 .subtitle {
    font-size: 1.125rem; /** 18px **/
    display: block;
    color: $gray;
    padding: 7px 0 0 0;
}

/**
    Pulpit po zalogowaniu się - prawa część - nawigacja
*/
.administrator .dashboard-navigation {
    margin: 72px 0 0 0;
}

.administrator .dashboard-navigation .navigation-item {
    /*max-width: 295px;*/
    height: 236px;
    margin-bottom: 37px;
}

.administrator .dashboard-navigation .navigation-item a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    text-align: center;
    font-family: "DINPro-Bold";
    font-size: 1.25rem; /** 20px **/
    color: $gray;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #fff;
    box-shadow: 0px 0px 12px 5px #efefef;
    border-radius: 5px;
    border: 1px #fff solid;

    transition: all 0.3s ease-in-out;
}

.administrator .dashboard-navigation .navigation-item a:hover {
    border: 1px $red solid;
    box-shadow: 0px 0px 9px 5px #efefef;
}

.administrator .dashboard-navigation .navigation-item a span {
    display: block;
    margin: 30px 0 0 0;
}

.administrator .dashboard-navigation .navigation-item a .img {
    text-align: center;
    width: 89px;
    height: 75px;
    display: flex;
    align-items: center;
}

.administrator .dashboard-navigation .navigation-item a img {
    display: block;
    margin: 0 auto;
}

/**
    Menu lewe
*/
.administrator .page-administrator .menu {
    background-color: #fff;
    box-shadow: 0px 0px 20px #efefef;
    width: 114px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    overflow-y: scroll;
    transition: all 0.2s ease-in-out;

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background: #d90010;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:active {
        background: #000000;
    }
    &::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
        background: #ffffff;
    }
    &::-webkit-scrollbar-track:active {
        background: #ff0505;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }

}

.administrator .page-administrator .menu.on {
    width: 315px;
}

.administrator .page-administrator .menu .menu-container {
    margin: 0 auto;
}

.administrator .page-administrator .menu .rc-logo {
    width: 62px;
    height: 74px;
    margin: 48px auto 48px auto;
    border-bottom: 1px solid $lightGray3;
}

.administrator .page-administrator .menu .rc-logo a {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.administrator .page-administrator .menu .rc-logo img {
    height: 74px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out;
}

.administrator .page-administrator .menu .rc-logo img:first-child {
    opacity: 1;
}

.administrator .page-administrator .menu .rc-logo img:last-child {
    width: 201px;
    opacity: 0;
}

.administrator .page-administrator .menu.on .rc-logo {
    width: 201px;
}

.administrator .page-administrator .menu.on img:first-child {
    opacity: 0;
}

.administrator .page-administrator .menu.on img:last-child {
    opacity: 1;
}

.administrator .page-administrator .menu .user-info-container {
    width: 246px;
    margin: 42px 0 0 30px;
}

.administrator .page-administrator .avatar {
    float: left;
    margin-right: 15px;
}

.administrator .page-administrator .menu .user-info-container .avatar img {
    width: 42px;
    margin: 0 auto;
    display: block;
}

.administrator .page-administrator .menu .user-name {
    float: left;
    width: 189px;
    overflow:hidden;
    opacity: 0;

    transition: all 0.2s ease-in-out;
}

.administrator .page-administrator .menu.on .user-name {
    opacity: 1;
}

.administrator .page-administrator .user-name > div {
    font-size: 1.125rem /** 18px **/
}

.administrator .page-administrator .menu nav {
    margin: 35px 0 0 35px;
}

.administrator .page-administrator .menu nav ul {
    padding: 0;
    margin: 0;
}

.administrator .page-administrator .menu nav ul li {
    width: 300px;
    list-style: none;
    height: 58px;
    border-left: 4px solid $white;
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
}

.administrator .page-administrator .menu nav ul li a {
    display: flex;
    align-items: center;
    width: 246px;
    color: $gray;
}

.administrator .page-administrator .menu nav ul li.active:after {
    content: "";
    height: 10%;
    width: 1px;
    border-left: 4px solid #D90010;
    position: absolute;
    left: 0;
}

.administrator .page-administrator .menu nav ul li.active a {
    color: $red;
    font-family: "DINPro-Bold";
}

.administrator .page-administrator .menu nav ul li a span {
    float: left;
    display: block;
    margin-left: 20px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.administrator .page-administrator .menu nav ul li a:hover {
    color: $red;
}

.administrator .page-administrator .menu.on nav ul li a span {
    opacity: 1;
}

.administrator .page-administrator .menu nav ul li .img {
    float: left;
    display: block;
    width: 30px;
}

.administrator .page-administrator .menu nav ul li a img {
    display: block;
    margin: 0 auto;
}

/**
   Główny kontent
*/
.administrator .main .go-back,
.retailer .main .go-back {
    margin-top: 40px;
}

.administrator .page-main-content .page-content-title h3,
.retailer .page-main-content .page-content-title h3 {
    color: $gray;
    font-size: 2.1875rem; /** 35px **/
    font-family: "DINPro-Bold";
    text-transform: uppercase;
    padding-top: 15px;
}

.administrator .page-main-content .page-content-subtitle legend,
.retailer .page-main-content .page-content-subtitle legend {
    margin-top: 57px;
    padding-bottom: 20px;
    border-bottom: 1px solid $lightGray3;
}

/*
 * Menu mobilne administrator/detalista
 */
.mobile-menu {
    display: block;
    position: fixed;
    width: 60%;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0px 5px 12px 0px #efefef;
    left: -100%;

    transition: all 0.3s ease-in-out;
}

.mobile-menu.on {
    left: 0;
}

.mobile-menu .user-info-container {
    margin-top: 60px;
}

.mobile-menu .user-info-container .avatar {
    float: left;
}

.mobile-menu .user-info-container .avatar img {
    width: 27px;
}

.mobile-menu .user-info-container .user-name {
    float: left;
    margin-left: 10px;
}

.mobile-menu .user-info-container .user-name a {
    font-size: 14px;
    color: $red;
    display: block;
}

.mobile-menu ul {
    margin: 30px 0 0 0;
    padding: 0;
}

.mobile-menu ul li {
    list-style: none;
    line-height: 49px;
}

.mobile-menu ul li.active a {
    color: $red;
    font-family: "DINPro-Bold";
}

.mobile-menu ul li a {
    color: #6A6A6A;
}

.mobile-menu ul li .img {
    width: 20px;
    float: left;
}

.mobile-menu ul li span {
    float: left;
    margin-left: 10px;
    display: block;
}

.mobile-menu li img {
    width: 18px;
}
