.retailer {

}

/**
    Pulpit po zalogowaniu się
 */
.retailer .page-retailer .page-retailer-container {
    width: 100%;
}

.retailer .user-info {
    position: fixed;
    /*width: 388px;*/
    width: 20.5%;
    height: 100%;
    box-shadow: 0px 0px 20px #efefef;
    left: 0;
    top: 0;

    /*background-image: url('/images/bg-dog.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 75%;*/
}

.retailer .user-info .dog-bg {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15%;
    margin: 0 auto;
}

.retailer .user-info .rc-logo {
    width: 290px;
    margin: 88px auto 0 auto;
    padding: 0 0 40px 0;
    text-align: center;
    border-bottom: 1px solid $lightGray3;
}

.retailer .user-info .user-info-container {
    width: 290px;
    margin: 50px auto 0 auto;
    text-align: center;
}

.retailer .user-info .user-info-container .avatar {
    margin: 0 0 10px 0;
}

.retailer .user-info .user-info-container .avatar img {
    display: block;
    margin: 0 auto;
}

.retailer .user-info .user-info-container .user-name {
    color: $gray;
    font-size: 1.875rem; /**30px**/
}

.retailer .user-info .user-info-container .user-name > div {
    line-height: 1.875rem; /**30px**/
}

.retailer .user-info .user-info-container .user-name a {
    font-size: 1.375rem; /**22px**/
    display: block;
    margin: 10px 0 0 0;
    text-decoration: none;
}

.retailer .user-info .user-info-container .user-name a:hover {
    font-family: "DINPro-Bold";
    color: $red;
}

.retailer .user-info .user-info-logout-container {
    margin: 37px 0 0 0;
}

.retailer .user-info .user-info-logout-container a {
    display: block;
    color: $gray;
    font-size: 1.125rem; /**18px**/
}


/**
    Pulpit po zalogowaniu się - prawa część
 */
.retailer .page-retailer .page-retailer-container .main {
    margin-left: 20.5%;
}

.retailer .header-container {
    margin: 48px 0 0 0;
}

.retailer .header-container a {
    color: $gray;
    font-size: 1.125rem; /**18px**/
    line-height: 1.125rem;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
}

.retailer .header-container a.active {
    font-family: "DINPro-Bold";
    color: $red;
}

.retailer .header-container a:hover {
    font-family: "DINPro-Bold";
}

.retailer .header-container a.home-btn span:first-child img:last-child {
    display: none;
}

.retailer .header-container a.home-btn:hover span:first-child img:first-child {
    display: none;
}

.retailer .header-container a.home-btn:hover span:first-child img:last-child {
    display: inline-block;
}

.retailer .header-container a.home-btn img {
    padding-right: 5px;
    display: inline-block;
}

.retailer .header-container .col-r ul {
    float: right;
    padding: 0;
    margin: 0;
}

.retailer .header-container .col-r ul li {
    list-style: none;
    display: inline-block;
}

.retailer .header-container .col-r ul li:not(:first-child):before {
    content: "|";
    color: $lightGray3;
}

.retailer .header-container .col-r ul li a {
    padding: 0 20px 0 20px;
}

.retailer .header-container .col-r .mobile-header-logo {
    width: 124px;
    display: none;
}

.retailer .header-container .mobile-menu-btn {
    font-size: 25px;
    display: none;
    margin-top: 3px;

    z-index: 101;
    position: absolute;
}

.retailer .page-title-container {
    margin: 78px 0 0 0;
    border-bottom: 1px solid $lightGray3;
}

.retailer .page-title-container h2 {
    color: $red;
    font-size: 1.5625rem; /** 25px **/
    padding: 0 0 7px 0;
}

.retailer .page-title-container h2 .subtitle {
    font-size: 1.125rem; /** 18px **/
    display: block;
    color: $gray;
    padding: 7px 0 0 0;
}

.retailer .page-dashboard {

}

.retailer .dashboard-panels .panel {
    box-shadow: 0px 0px 20px #efefef;
    margin-right: 43px;
    max-width: 30%;
    height: 445px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
}

.retailer .dashboard-panels .panel:last-child {
    margin-right: 0;
}

.retailer .dashboard-panels h3 {
    font-size: 1.875rem; /*30px;*/
    text-transform: uppercase;
    font-family: 'DINPro-Medium';
    text-align: center;
    margin: 0;
}

.retailer .dashboard-panels .code-form h3 {
    padding-bottom: 65px;
}

.retailer .dashboard-panels .my-codes,
.retailer .dashboard-panels .settlement {
    transition: all 0.3s ease-in-out;
}

.retailer .dashboard-panels .my-codes:hover,
.retailer .dashboard-panels .settlement:hover {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border: 1px $red solid;
}

.retailer .dashboard-panels .my-codes h3,
.retailer .dashboard-panels .settlement h3 {
    padding-bottom:35px;
    color: $gray;
}

.retailer .dashboard-panels .panel-content {
    margin: 0 auto;
}

.retailer .dashboard-panels a.panel-content:hover {
    text-decoration: none;
}

.retailer .dashboard-panels .panel-content .number {
    text-align: center;
    font-size: 8.125rem; /*130px;*/
    color: $red;
    font-family: 'DINPro-Black';
}

.retailer .dashboard-panels .panel-content .text {
    text-align: center;
    font-size: 1.5625rem; /*25px;*/
    color: $gray;
}

.retailer .dashboard-panels .code-form .form-control {
    height: 63px;
    text-align: center;
}









