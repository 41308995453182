// Body
$body-bg: #fff;

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1320px
);

// Typography
$font-family-din-pro: 'DINPro-Regular', 'DINPro-Bold', 'DINPro-Black', 'DINPro-Light', 'DINPro-Medium', sans-serif;
$font-family-base: $font-family-din-pro;
$font-size-base: 1rem;
$line-height-base: 1.6;
$h1-font-size: $font-size-base * 2.813;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.125;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #D90010;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #07923A;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray: #6a6a6a;
$lightGray: #F8F8F8;
$lightGray2: #D1D1D1;
$lightGray3: #ededed;
$white: #ffffff;
$lightGreen: #01BB26;
$lightRed: #e81d25;

// Themes
$theme-colors: (
  "primary": #D90010,
  "danger": #ff4136
);

// Dropdowns
$dropdown-link-hover-bg: $red;
$dropdown-link-hover-color: #fff;

// Pretty checkbox
$pretty--color-danger: $red;
