/**

 */

body {
    color: #6A6A6A;
}

.clear {
    clear: both;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

* html .clearfix {
    zoom: 1;
}

img {
    max-width: 100%;
}


/**
    Strona wejściowa - gość
*/
body.guest-body {
    background-image: url(/images/bg-dog.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
}
