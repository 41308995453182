.red {
    color: $red;
}

.gray {
    color: $gray;
}

.black {
    color: #000;
}

.green {
    color: $green;
}

.light-green {
    color: $lightGreen;
}

.light-gray-3 {
    color: $lightGray3;
}

.w-10 {
    width: 10% !important;
}

legend {
    font-size: 1.25rem;
}

::-moz-selection { /* Code for Firefox */
    background: $red;
    color: #fff;
}

::selection {
    background: $red;
    color: #fff;
}

::marker {
    color: $red;
}
