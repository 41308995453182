.air-datepicker {
    --adp-accent-color: #D90010 !important;
    --adp-btn-color: #D90010 !important;
    --adp-font-family: "DINPro-Regular" !important;
    --adp-cell-background-color-selected: #D90010 !important;
    --adp-cell-background-color-selected-hover: #b3000d !important;
    --adp-btn-background-color-hover: #b3000d !important;
    --adp-background-color-selected-other-month: #ffb0b6 !important;
    --adp-background-color-selected-other-month-focused: #ffb0b6 !important;
}

.air-datepicker-nav--title,
.air-datepicker-cell.-current- {
    font-family: "DINPro-Bold";
}

.air-datepicker--buttons {
    background-color: $red;
}

.air-datepicker--buttons:hover {
    background-color: #b3000d;
}

.air-datepicker-button span {
    color: #fff;
    text-transform: uppercase;
    font-family: "DINPro-Bold";
}

.air-datepicker-field[readonly] {
    background-color: #fff;
}
