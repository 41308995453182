/* #SDK
========================================================================== */
#onetrust-banner-sdk {
    margin-bottom: 0!important;
}

#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 105;
    font-size: 1em !important;
}
