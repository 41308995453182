@media (max-width: 1680px)  {
    /***=== GOŚĆ ===***/
    body.guest-body {
        background-size: 500px;
        background-position: left bottom;
    }
    /***=== ADMINISTRATOR ===***/
    .administrator .header-container > .container {
        max-width: 85%;
    }

    .administrator .main-content > .container {
        max-width: 85%;
    }

    .administrator footer > .container {
        max-width: 85%;
    }

    .administrator .page-administrator .main {
        margin-left: 5%;
    }

    .administrator .page-dashboard .dashboard-user-info .rc-logo {
        margin: 48px auto 0 auto;
        width: 50%;
    }

    .administrator .page-dashboard .dashboard-user-info .dog-bg {
        width: 90%;
    }

    .administrator .administrator .dashboard-navigation .navigation-item {
        max-width: 21%;
    }


    /***=== DETALISTA ===***/
    .retailer .header-container > .container {
        max-width: 85%;
    }

    .retailer .main-content > .container {
        max-width: 85%;
    }

    .retailer footer > .container {
        max-width: 85%;
    }

    .retailer .page-administrator .main {
        margin-left: 5%;
    }

    .retailer .page-retailer .user-info .rc-logo {
        margin: 48px auto 0 auto;
        width: 50%;
    }

    .retailer .page-retailer .user-info .dog-bg {
        width: 90%;
    }
}

@media (max-width: 1609px)  {
    table.dataTable#table-campaigns thead .sorting:before,
    table.dataTable#table-campaigns thead .sorting_asc:before,
    table.dataTable#table-campaigns thead .sorting_desc:before,
    table.dataTable#table-campaigns thead .sorting_asc_disabled:before,
    table.dataTable#table-campaigns thead .sorting_desc_disabled:before {
        padding-bottom: 21px;
    }

    table.dataTable#table-campaigns thead .sorting:after,
    table.dataTable#table-campaigns thead .sorting_asc:after,
    table.dataTable#table-campaigns thead .sorting_desc:after,
    table.dataTable#table-campaigns thead .sorting_asc_disabled:after,
    table.dataTable#table-campaigns thead .sorting_desc_disabled:after {
        padding-bottom: 21px;
    }
}

@media (max-width: 1440px)  {

    /***=== ADMINISTRATOR ===***/
    .administrator .page-dashboard .dashboard-user-info .user-info-container {
        width: 90%;
    }

    .administrator .dashboard-navigation .navigation-item a {
        font-size: 1.125rem; /** 18px **/
    }

    .administrator .page-administrator .menu .rc-logo {
        margin: 28px auto 28px auto;
    }

    .administrator .page-administrator .menu .user-info-container {
        margin: 22px 0 0 30px;
    }

    .administrator .page-administrator .menu nav {
        margin: 15px 0 0 35px;
    }

    .administrator .page-administrator .menu nav ul li {
        height: 45px;
    }

    /***=== DETALISTA ===***/
    .retailer .page-retailer .user-info .user-info-container {
        width: 90%;
    }

}

@media (max-width: 1366px)  {

    /***=== ADMINISTRATOR ===***/
    .administrator .page-dashboard .dashboard-user-info .dog-bg {
        width: 70%;
    }

    .administrator .page-administrator .menu nav ul li {
        height: 32px;
    }

    /***=== DETALISTA ===***/
    .retailer .page-retailer .user-info .dog-bg {
        width: 70%;
    }
}

@media (max-width: 1200px)  {
    /***=== GOŚĆ ===***/
    body.guest-body {
        background-size: 400px;
    }
}

@media (max-width: 1024px)  {
    html {
        font-size: 14px;
    }
    
    table.dataTable#table-campaigns thead .sorting:before,
    table.dataTable#table-campaigns thead .sorting_asc:before,
    table.dataTable#table-campaigns thead .sorting_desc:before,
    table.dataTable#table-campaigns thead .sorting_asc_disabled:before,
    table.dataTable#table-campaigns thead .sorting_desc_disabled:before {
        padding-bottom: 0;
    }

    table.dataTable#table-campaigns thead .sorting:after,
    table.dataTable#table-campaigns thead .sorting_asc:after,
    table.dataTable#table-campaigns thead .sorting_desc:after,
    table.dataTable#table-campaigns thead .sorting_asc_disabled:after,
    table.dataTable#table-campaigns thead .sorting_desc_disabled:after {
        padding-bottom: 0;
    }
}

@media (max-width: 960px)  {
    /***=== GOŚĆ ===***/
    body.guest-body {
        background-size: 300px;
    }

    html {
        font-size: 14px;
    }

    .mobile-menu {
        overflow: auto;
    }

    /***=== ADMINISTRATOR ===***/
    .administrator .page-administrator .main {
        margin-left: 0;
    }

    .administrator .page-dashboard .dashboard-user-info {
        display: none;
    }

    .administrator .header-container .mobile-menu-btn {
        display: block;
    }

    .administrator .header-container .col-r .mobile-header-logo {
        display: inline-block;
    }

    .administrator .header-container {
        margin: 0;
        padding: 5px 0;
        box-shadow: 0px 5px 12px 0px #efefef;
    }

    .administrator .header-container .container {
        max-width: 100%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .administrator .header-container .home-btn {
        display: none;
    }

    .administrator .header-container .col-r ul {
        display: none;
    }

    .administrator .page-dashboard .page-dashboard-container .main {
        margin-left: 0;
    }

    .administrator .dashboard-navigation .navigation-item {
        max-width: inherit;
        padding: 0 15px 0 15px;
        margin: 0 0px 37px 0;
    }

    .administrator .page-administrator .menu {
        display: none;
    }

    /***=== DETALISTA ===***/
    .retailer .page-retailer .page-retailer-container .main {
        margin-left: 0;
    }

    .retailer .user-info {
        display: none;
    }

    .retailer .header-container .mobile-menu-btn {
        display: block;
    }

    .retailer .header-container .col-r .mobile-header-logo {
        display: inline-block;
    }

    .retailer .header-container {
        margin: 0;
        padding: 5px 0;
        box-shadow: 0px 5px 12px 0px #efefef;
    }

    .retailer .header-container .container {
        max-width: 100%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .retailer .header-container .home-btn {
        display: none;
    }

    .retailer .header-container .col-r ul {
        display: none;
    }

    .retailer .dashboard-panels .panel {
        margin-bottom: 30px;
        max-width: 45%;
        margin-right: 5%;
    }
}

@media (max-width: 720px)  {

    /***=== GOŚĆ ===***/
    body.guest-body {
        background-size: 200px;
    }

}

//max-width: 575px
@include media-breakpoint-only(xs) {

    html {
        font-size: 14px;
    }

    /***=== GOŚĆ ===***/
    body.guest-body {
        background: none;
    }

    footer {
        margin-top: 1rem;
    }

    div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item .page-link {
        font-size: 0.688rem;
        padding: 0.125rem 0.5rem;
    }


    /***=== ADMINISTRATOR ===***/
    .administrator .page-administrator .main {
        margin-left: 0;
    }

    .administrator .page-dashboard .dashboard-user-info {
        display: none;
    }

    .administrator .header-container .mobile-menu-btn {
        display: block;
    }

    .administrator .header-container .col-r .mobile-header-logo {
        display: inline-block;
    }

    .administrator .header-container {
        margin: 0;
        padding: 5px 0;
        box-shadow: 0px 5px 12px 0px #efefef;
    }

    .administrator .header-container .container {
        max-width: 100%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .administrator .header-container .home-btn {
        display: none;
    }

    .administrator .header-container .col-r ul {
        display: none;
    }

    .administrator .page-dashboard .page-dashboard-container .main {
        margin-left: 0;
    }

    .administrator .dashboard-navigation .navigation-item a {
        font-size: 13px;
    }

    .administrator .page-administrator .menu {
        display: none;
    }

    /***=== DETALISTA ===***/
    .retailer .page-retailer .page-retailer-container .main {
        margin-left: 0;
    }

    .retailer .user-info {
        display: none;
    }

    .retailer .header-container .mobile-menu-btn {
        display: block;
    }

    .retailer .header-container .col-r .mobile-header-logo {
        display: inline-block;
    }

    .retailer .header-container {
        margin: 0;
        padding: 5px 0;
        box-shadow: 0px 5px 12px 0px #efefef;
    }

    .retailer .header-container .container {
        max-width: 100%;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .retailer .header-container .home-btn {
        display: none;
    }

    .retailer .header-container .col-r ul {
        display: none;
    }

    .retailer .dashboard-panels .panel {
        max-width: inherit;
        margin-right: 0;
        margin-bottom: 30px;
    }
}


@media (max-width: 375px)  {

    html {
        font-size: 14px;
    }

    /***=== ADMINISTRATOR ===***/
    .administrator .dashboard-navigation .navigation-item a {
        font-size: 13px;
    }

    /***=== DETALISTA ===***/
    .retailer .dashboard-panels .panel {
        margin-right: 0;
        margin-bottom: 30px;
    }

}
