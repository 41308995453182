@font-face {
    font-family: "DINPro-Light";
    src: url("../fonts/DINPro-Light.ttf") format('truetype');
}

@font-face {
    font-family: "DINPro-Regular";
    src: url("../fonts/DINPro-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "DINPro-Medium";
    src: url("../fonts/DINPro-Medium.ttf") format('truetype');
}

@font-face {
    font-family: "DINPro-Bold";
    src: url("../fonts/DINPro-Bold.ttf") format('truetype');
}

@font-face {
    font-family: "DINPro-Black";
    src: url("../fonts/DINPro-Black.otf") format('truetype');
}

.font-family-light {
    font-family: "DINPro-Light";
}

.font-family-regular {
    font-family: "DINPro-Regular";
}

.font-family-medium {
    font-family: "DINPro-Medium";
}

.font-family-bold {
    font-family: "DINPro-Bold";
}

.font-family-black {
    font-family: "DINPro-Black";
}
